
import {computed, defineComponent, onMounted, ref} from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import MachineCard from "@/components/certification/machine/machine_card.vue";
import MachineActivity from "@/components/certification/machine/machine_activity.vue";
import MachineTimeline from "@/components/certification/machine/machine_timeline.vue";
import empty from "../../../../layout/header/partials/search/Empty.vue";

export default defineComponent({
  name: "machine_show",
  computed: {
    empty() {
      return empty
    }
  },
  props: {
    uuid: {
      type: String,
      default: null,
    },
  },
  beforeRouteUpdate(to, from, next) {
    // update machine content baseed on uuid
    this.store.commit(Mutations.SET_CURRENT_MACHINE_CERTIFICATION, {});
    this.store.dispatch(Actions.GET_MACHINE_CERTIFICATION, { id: to.params.machine_uuid });
    next();
  },
  components: {
    MachineCard,
    MachineActivity,
    MachineTimeline,
  },
  setup(props) {
    const store = useStore();
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));
    const loading = ref(false);
    const machine = computed(() => {
        return store.getters.getCurrentMachine;
    });
    onMounted(() => {
      loading.value = true;
      try{
        store.commit(Mutations.SET_CURRENT_MACHINE_CERTIFICATION, {});
        store.dispatch(Actions.GET_MACHINE_CERTIFICATION, { id: props.uuid });
      }catch (e) {
        console.log(e)
      }finally {
        loading.value = false;
      }
    });

    return {
      moment,
      machine,
      store,
      loading,
      currentLanguage
    };
  },
});
