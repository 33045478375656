
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {MachineType} from "@/types/MachineType";
import { useI18n } from "vue-i18n";
import router from "@/router";
export default defineComponent({
  name: "machine_card",
  data() {
    return {
      release_countdown: "",
      release_countdown_started: false,
      countdown: "",
      countdown_started: false,
      loading: false,
      flag_input: ""
    };
  },
  props: {
    machine: {
      type: Object as () => MachineType,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

    const user = computed(() => {
      return store.getters.currentUser;
    });

    return {
      moment,
      user,
      store,
      currentLanguage
    };
  },
  created() {
    if (!this.countdown_started) {
      if (this.machine.instance) {
        setInterval(this.update, 1000);
        this.countdown_started = true;
      }
    }

    if (!this.release_countdown_started) {
      if (this.machine) {
        setInterval(this.update_release_countdown, 1000);
        this.release_countdown_started = true;
      }
    }
  },
  methods: {
    update_release_countdown: function () {
      if (this.machine.release_at) {
        const now = moment.utc();
        const end = moment.utc(this.machine.release_at);
        const duration = moment.duration(end.diff(now));
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        const days = duration.days();

        if(days <=0 &&hours <= 0 && minutes <= 0 && seconds <= 0){
          this.release_countdown = '';
        }else{
          this.release_countdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
      }
    },
    update: function (machine) {
      const now = moment.utc();
      const end = machine ? moment.utc(machine.instance.shutdown) : moment.utc(this.machine.instance.shutdown);
      const duration = moment.duration(end.diff(now));
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      if(days <=0 && hours <= 0 && minutes <= 1 && seconds == 59){
        // play alert sound
        this.play_alert_sound();
      }

      if(days <=0 && hours <= 0 && minutes <= 0 && seconds == 20){
        // play alert sound
        this.play_alert_sound();
      }
      if(days <=0 && hours <= 0 && minutes <= 0 && seconds <= 0){
        this.countdown = '';

      }else{
        this.countdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      }
      
    },
    StopInstance: function(){
      if(this.loading){
        return;
      }
      this.loading = true;
      ApiService.get("labs/machines/" + this.$props.machine.id + "/stop")
      .then(({ data }) => {
        if(data.success){
           this.store.commit(Mutations.SET_CURRENT_MACHINE, data.machine);
           this.loading = false;
        }else{
          Swal.fire({
            title: "Oops...",
            text: data.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
          this.store.commit(Mutations.SET_CURRENT_MACHINE, data.machine);
          this.loading = false;
        }
      }).catch(error => {
       Swal.fire({
          title: "Oops...",
          text: this.$t(`${this.currentLanguage}.messages.we_had_a_problem_closing_your_instance_contact_support`),
          icon: "error",
          confirmButtonText: "Ok",
        });
        this.loading = false;
      });
    },
    StartInstance: function(){
        if(this.loading){
          return;
        }
        this.loading = true;
      ApiService.get("certification/start/"+1+"/" + this.machine.id)
        .then(({ data }) => {
          Swal.fire({
            title: "OK!",
            text: data.message,
            icon: "success",
            confirmButtonText: "Ok",
          });
          this.loading = false;
          this.store.commit(Mutations.SET_CURRENT_MACHINE, this.machine);
          this.countdown_started = true;
          window.document.location.reload();
        }).catch(error => {
          if(error.response.data.message){
            Swal.fire({
              title: "Oops...",
              text: error.response.data.message,
              icon: "warning",
              confirmButtonText: "Ok",
            });
          }else{
           Swal.fire({
              title: "Oops...",
              text: this.$t(`${this.currentLanguage}.messages.we_had_a_problem_starting_your_instance_please_try_again_later`),
              icon: "error",
              confirmButtonText: "Ok",
            });
            }
             this.loading = false;
        });
    },
    submitFlag(){
      if(!this.flag_input){
        Swal.fire({
          title: "Oops...",
          text: this.$t(`${this.currentLanguage}.messages.please_insert_a_flag`),
          icon: "error",
          confirmButtonText: "Ok",
        });
        return;
      }
      ApiService.vueInstance.axios.put("/certification/machines/pown/"+this.machine.id, {
        flag: this.flag_input
      }).then(({ data }) => {
        if(data.success){
          Swal.fire({
            title: this.$t(`${this.currentLanguage}.messages.congratulations`),
            text: this.$t(`${this.currentLanguage}.messages.you_hit_the_flag`),
            icon: "success",
            confirmButtonText: "Ok",
          });
        }else{
          Swal.fire({
            title: "Oops...",
            text: data.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }).catch(error => {
        Swal.fire({
          title: "Oops...",
          text: this.$t(`${this.currentLanguage}.messages.we_had_a_problem_verifying_your_flag_please_try_again`),
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
     
    },
    play_alert_sound(){
      const audio = new Audio('/media/alert.mp3');
      audio.play();
    }
  },
});
